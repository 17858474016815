@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

body {
  text-align: center;

  /* Regular background: */
  background: #e8dcb2;
}

/* Dark theme background: */
body.dark {
  background: rgb(25, 25, 25);
}
