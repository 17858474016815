$hex-color: #000 !default;
$hex-item-active-background: green !default;
$hex-item-active-color: white !default;
$hex-set-active-color: green !default;
$hex-ascii-color: #555 !default;
$hex-font-family: monoscape !default;

$hex-color-dark: white !default;
$hex-item-active-background-dark: rgb(0, 207, 0) !default;
$hex-item-active-color-dark: white !default;
$hex-set-active-color-dark: rgb(0, 207, 0) !default;
$hex-ascii-color-dark: rgb(173, 173, 173) !default;
$hex-font-family-dark: monoscape !default;